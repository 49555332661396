import { sendSingleRequest } from '../../apis';
import { IThunkType, RootState } from '../rootReducer';
import { TExtraAction } from './extra.type';

const setExtra = (extra: TExtraAction): TExtraAction => extra;

let loadingExtraData = false;

export const loadExtraData = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingExtraData) return true;
    loadingExtraData = true;

    const response = await sendSingleRequest({}, 'GET', 'api/farm/extra', true);
    loadingExtraData = false;

    if (!response.status) {
      return false;
    }

    dispatch(
      setExtra({ type: 'EXTRA/SET_EXTRA_FARMS', payload: response.data.farms }),
    );
    dispatch(
      setExtra({
        type: 'EXTRA/SET_EXTRA_SPAT_STORAGES',
        payload: response.data.spat_storages,
      }),
    );
    dispatch(
      setExtra({
        type: 'EXTRA/SET_EXTRA_INVENTORIES',
        payload: response.data.inventories,
      }),
    );
    dispatch(
      setExtra({ type: 'EXTRA/SET_EXTRA_UTILS', payload: response.data.utils }),
    );
    dispatch(
      setExtra({
        type: 'EXTRA/SET_CUSTOM_FIELDS',
        payload: response.data.custom_fields,
      }),
    );
    dispatch(setExtra({ type: 'EXTRA/SET_TAGS', payload: response.data.tags }));
    dispatch(
      setExtra({ type: 'EXTRA/SET_ACCOUNTS', payload: response.data.accounts }),
    );

    return true;
  };
};
